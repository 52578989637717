interface WidthLogo {
  width?: number;
}

const InkLogo = ({ width = 290, ...props }: WidthLogo) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 290 290"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M233.274 212.454C220.117 234.286 203.49 227.346 187.875 218.093C172.26 208.84 157.513 197.707 148.115 210.864C137.849 222.141 145.801 240.214 149.85 256.118C153.898 272.167 154.187 286.047 128.596 289.083C108.354 286.336 109.511 269.565 112.836 251.781C116.162 233.997 121.367 215.201 108.354 208.551C97.9442 201.611 90.715 213.611 81.6063 225.467C72.2083 237.178 61.5092 248.6 43.87 240.214C27.9658 227.057 34.0383 219.394 42.4242 209.563C50.81 199.731 61.6538 186.574 55.4367 163.441C52.8342 154.043 39.8217 156.211 26.9538 155.922C14.375 155.633 1.65167 153.031 0.350421 134.235C-0.661662 122.813 7.86876 118.186 16.6883 114.571C25.6525 110.957 34.7613 108.354 34.9058 99.2455C35.3396 90.4259 29.4117 84.2089 25.6525 77.7026C21.8933 71.1964 20.3029 64.4009 29.2671 54.2801C44.4483 41.8459 56.5933 52.4005 68.7383 63.8226C80.8833 75.2447 92.8838 87.2451 107.776 77.8472C119.632 70.0397 108.788 56.3043 100.402 42.858C92.0163 29.4118 86.0883 16.5438 107.776 10.6159C126.572 5.41093 133.801 18.2788 140.307 33.1709C146.958 48.063 152.741 64.9793 168.645 68.0155C191.345 72.2084 207.828 45.6051 223.587 24.4959C239.347 3.38676 254.817 -12.3728 274.625 13.6522C296.313 40.9784 275.637 53.7018 251.492 64.9793C227.346 75.5339 199.586 84.7872 206.237 105.173C210.141 117.029 222.864 110.523 236.31 106.041C249.612 101.559 263.926 99.1009 270.866 119.487C278.24 141.03 260.745 145.946 241.082 147.97C221.418 149.994 199.442 149.127 197.996 158.958C196.405 169.224 209.563 173.417 220.985 179.489C232.407 185.706 242.094 193.948 233.274 212.454ZM267.396 245.708C253.66 245.708 246.576 235.009 246.576 224.021C246.576 213.033 253.516 202.333 267.396 202.333C281.854 202.333 289.083 213.033 289.083 224.021C289.083 235.009 281.854 245.708 267.396 245.708Z"
      fill="black"
    />
  </svg>
);

export default InkLogo;
