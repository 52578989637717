import { SVGProps } from "react";

const Crown = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1757_4811)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1714 4.89411C21.0398 4.7055 20.8645 4.55147 20.6606 4.4451C20.4566 4.33874 20.23 4.2832 20 4.2832C19.77 4.2832 19.5434 4.33874 19.3394 4.4451C19.1355 4.55147 18.9602 4.7055 18.8286 4.89411L9.80572 17.7855L2.44 10.417C2.2402 10.2167 1.98543 10.0802 1.70798 10.0249C1.43052 9.96953 1.14289 9.99782 0.881536 10.1062C0.620183 10.2145 0.396883 10.398 0.239945 10.6334C0.0830058 10.8688 -0.000503899 11.1455 2.28757e-06 11.4284V29.9998C2.28757e-06 31.5153 0.602041 32.9688 1.67368 34.0404C2.74531 35.1121 4.19876 35.7141 5.71429 35.7141H34.2857C35.8012 35.7141 37.2547 35.1121 38.3263 34.0404C39.398 32.9688 40 31.5153 40 29.9998V11.4284C40.0005 11.1455 39.917 10.8688 39.7601 10.6334C39.6031 10.398 39.3798 10.2145 39.1185 10.1062C38.8571 9.99782 38.5695 9.96953 38.292 10.0249C38.0146 10.0802 37.7598 10.2167 37.56 10.417L30.1943 17.7855L21.1714 4.89411Z"
        fill="#FFE34E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1757_4811">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Crown;
