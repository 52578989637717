const theme = {
  black: "#000000",
  pink: "#FF48AB",
  white: "#FFFFFF",
  insertBlue: "#007CFF",
  green: "#7ECE40",
  warningRed: "#E33434",
  correctGreen: "#24B984",

  grey100: "#F2F2F2",
  grey200: "#D9D9D9",
  grey300: "#CCCCCC",
  grey400: "#B3B3B3",
  grey500: "#999999",
  grey600: "#808080",
  grey700: "#666666",
  grey800: "#4D4D4D",
  grey900: "#333333",

  blueLight: "#E6F2FF",
  blueLightHover: "#D9EBFF",
  blueLightActive: "#B0D6FF",
  blueNormal: "#007CFF",
  blueNormalHover: "#0070E6",
  blueNormalActive: "#0063CC",
  blueDark: "#005DBF",
  blueDarkHover: "#004A99",
  blueDarkActive: "#003873",
  blueDarker: "#002859",

  BLUE: "#6B71FF",
  PINK: "#FF6AC3",
  RED: "#FF6B6B",
  PURPLE: "#C446EE",
  NEON: "#65EE83",
  ORANGE: "#FF984D",
  SKYBLUE: "#5CE2FF",
};

export default theme;
